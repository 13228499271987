const plansSwitcher = document.getElementById('switch');
const plansList = document.getElementById('plans-list');
if (plansSwitcher) {
    plansSwitcher.addEventListener('change', (e) => {
        const className = 'half-year';
        if (e.target.checked) {
            plansList.classList.add(className);
        } else {
            plansList.classList.remove(className);
        }
    });
}
