const icon = document.getElementById('mobile-menu-icon');
const mobileMenu = document.getElementById('mobile-menu');
const closeIcon = document.getElementById('close-icon');

icon.addEventListener('click', (e) => {
    e.preventDefault();
    addClass(mobileMenu, 'active');
    addClass(document.body, 'overflow-hidden');
});
closeIcon.addEventListener('click', (e) => {
    e.preventDefault();
    removeClass(mobileMenu, 'active');
    removeClass(document.body, 'overflow-hidden');
});

function addClass(element, className) {
    element.classList.add(className);
}
function removeClass(element, className) {
    element.classList.remove(className);
}
