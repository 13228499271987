const welcomeLogo = document.getElementById('welcome-logo');
const welcomeButton = document.getElementById('welcome-button');
const welcomeSection = document.getElementById('welcome-section');
const mainSection = document.getElementById('main-section');
const header = document.getElementById('header');
const mainWrapper = document.getElementById('main-wrapper');
const footer = document.getElementById('footer');

if (welcomeLogo && welcomeButton) {
    welcomeLogo.addEventListener('click', (e) => {
        showMainSection();
        e.preventDefault();
    });
    welcomeButton.addEventListener('click', (e) => {
        e.preventDefault();
        showMainSection();
    });
}

function showMainSection() {
    welcomeSection.classList.add('hide-welcome');
    footer.classList.add('hide-welcome');
    localStorage.setItem('isLoaded', 'true');
    setTimeout(() => {
        welcomeSection.classList.add('hide');
    }, 1000);
    setTimeout(() => {
        footer.classList.remove('hide-welcome');
        mainSection.classList.remove('hide-scale');
        header.classList.remove('hide-scale');
        mainWrapper.classList.remove('hide-background');
    }, 1200);
}

if (mainSection) {
    window.addEventListener('scroll', (e) => {
        if (window.scrollY > 200) {
            header.classList.add('header-active-bg');
        } else {
            header.classList.remove('header-active-bg');
        }
    });
}
